import cn from 'classnames';
import * as React from 'react';
import styles from './paw.module.css';

const PawLoader: React.FC<{ className?: string }> = ({
  className = 'text-primary-main ',
}) => {
  const pawIcon = (): React.ReactNode => {
    return (
      <svg
        viewBox="0 0 20 20"
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.81 9.02c1.93 0 3.18 1.61 3.1 3.65 0 .6-.09 1.1-.25 1.62l-.25.68c-1.42 3.22-4.6 4.84-4.6 4.84a2.1 2.1 0 01-1.75 0s-3.18-1.62-4.6-4.84a5.44 5.44 0 01-.5-2.3c0-2.04 1.25-3.65 3.17-3.65 1.34 0 1.93.93 2.84.93 1 0 1.5-.93 2.84-.93zm6.6-4.93c.76.51.84 2.3-.08 3.74-1 1.36-2.25 2.12-3.42 1.36-1.17-.85-.92-2.3.08-3.74 1-1.36 2.67-1.86 3.43-1.36zm-18.8 0c.76-.5 2.43 0 3.43 1.36 1 1.45 1.25 2.9.09 3.74-1.17.76-2.43 0-3.43-1.36C-.3 6.4-.14 4.6.62 4.1zM13.4.02c1 .25 1.92 1.86 1.59 3.65-.34 1.78-1.26 3.14-2.76 2.89-1.5-.26-1.92-1.87-1.59-3.66.33-1.78 1.76-3.06 2.76-2.88zm-6.85 0c1-.18 2.43 1.1 2.76 2.88.33 1.79-.09 3.4-1.6 3.66-1.5.25-2.41-1.1-2.75-2.9-.33-1.78.59-3.39 1.6-3.64z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    );
  };
  return (
    <div
      className={cn(
        styles.pawLoader,
        'relative mb-8 mt-8 flex h-6 w-6 items-center justify-center',
        className
      )}
    >
      <div className="absolute left-0 top-0">{pawIcon()}</div>
      <div className="absolute left-0 top-0">{pawIcon()}</div>
      <div className="absolute left-0 top-0">{pawIcon()}</div>
    </div>
  );
};

export default PawLoader;
