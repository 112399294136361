import React, { useMemo } from 'react';
import BannerExtraLong from './bannerExtraLong.svg';
import BannerLong from './bannerLong.svg';
import BannerShort from './bannerShort.svg';

export interface RibbonTextProps {
  color?:
    | 'blue'
    | 'green'
    | 'orange'
    | 'khaki'
    | 'pantone'
    | 'pink'
    | 'plum'
    | 'purple'
    | 'red'
    | 'sandy-brown'
    | 'soft-blue'
    | 'soft-cyan'
    | 'soft-green'
    | 'soft-orange'
    | 'soft-pink'
    | 'soft-purple'
    | 'soft-red'
    | 'soft-turquoise'
    | 'soft-yellow'
    | 'sky-blue'
    | 'turquoise'
    | 'yellow';
  /** Style to be applied for specific purpose, such as when there's background color and mixBlendMode doesn't work as expected   */
  styleOverride?: React.CSSProperties;
  /** Style to be applied for specific purpose, such as when we need to tweak mixBlendMode */
  commonStyleOverride?: React.CSSProperties;
}

const RibbonText: React.FC<React.PropsWithChildren<RibbonTextProps>> = ({
  children,
  color = 'pink',
  styleOverride = {},
  commonStyleOverride = {},
}) => {
  const bannerComponent = useMemo(() => {
    const wordLength = children ? children.toString().length : 0;

    const commonStyle: React.CSSProperties = {
      width: '100%',
      height: '100%',
      mixBlendMode: 'darken',
      ...commonStyleOverride,
    };

    switch (true) {
      case wordLength >= 15:
        return (
          <BannerExtraLong
            className={`absolute text-new-brand-${color}`}
            style={{
              transform: 'scaleY(1.15) scaleX(1.15)',
              ...commonStyle,
            }}
          />
        );
      case wordLength > 10:
        return (
          <BannerLong
            className={`absolute text-new-brand-${color}`}
            style={{
              transform: 'scaleY(1.35) scaleX(1.23)',
              ...commonStyle,
            }}
          />
        );
      case wordLength <= 10:
        return (
          <BannerShort
            className={`absolute text-new-brand-${color}`}
            style={{
              transform: 'scaleY(1.35) scaleX(1.25)',
              ...commonStyle,
            }}
          />
        );
      default:
        return (
          <BannerShort
            className={`absolute text-new-brand-${color}`}
            style={commonStyle}
          />
        );
    }
  }, [children, color, commonStyleOverride]);

  return (
    <span
      className="relative inline-block whitespace-nowrap leading-none"
      style={styleOverride}
    >
      {bannerComponent}
      {children}
    </span>
  );
};

export default RibbonText;
