import { ImageLoader } from 'next/image';

const normalizeSrc = (src: string): string => {
  return src[0] === '/' ? src.slice(1) : src;
};

/**
 * Image loader implementation for Shopify and PDEX images used with `Image` component from Next.js.
 * @see https://nextjs.org/docs/api-reference/next/image#loader
 */
const generalImageLoader: ImageLoader = ({ src, width }) => {
  const url = new URL(src);

  if (url.hostname.indexOf('petsdeli') > -1) {
    // Add a new query parameter
    url.searchParams.append('w', String(width));
    url.searchParams.append('f', 'jpg');
    return url.href;
  } else {
    let newSrc = normalizeSrc(src).split('.jpg').join(`_${width}x.jpg`);
    newSrc = newSrc.split('.png').join(`_${width}x.png`);
    return newSrc;
  }
};

/**
 * Image loader implementation for Contentful images used with `Image` component from Next.js.
 * @see https://nextjs.org/docs/api-reference/next/image#loader
 */
const contentful: ImageLoader = ({ src, width, quality }) => {
  const params = ['w=' + width];

  if (quality) {
    params.push('q=' + quality);
  }

  return `https:/${normalizeSrc(src)}?${params.join('&')}`;
};

export { contentful, generalImageLoader };
